import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Map from './pages/Map';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Config from './pages/Config';
import LoginContainer from './components/LoginContainer';
import { Preferences } from '@capacitor/preferences';
import { useState } from 'react';

setupIonicReact();


const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
const checkName = async () => {
  const { value } = await Preferences.get({ key: 'api_key' });
  if (value && value.length != 0){
    setIsLoggedIn(true);
    console.log(`${value} testing 1  23 4`);
  }else{
    setIsLoggedIn(false);
  }
};
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/page/Login" />
            </Route>
            <Route path="/page/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/page/Map" exact={true} render={()=>{
              checkName();
              return isLoggedIn ? <Map/> : <LoginContainer/>
              
            }}> 
            </Route>
            <Route path="/page/AppConfiguration" exact={true} render={(props)=>{
              console.log(isLoggedIn);
              console.log(props);
              checkName();
              return isLoggedIn ? <Config/> : <LoginContainer/>
              
            }}> 
              {/* <Config/> */}
            </Route>
            <Route path="/page/Login" exact={true} render={()=>{
              return <LoginContainer/>
            }}>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
