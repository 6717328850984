import { useFormInput } from "./utils";
export enum TextFieldTypes {
    password = "password",
    email = "email", 
    text = "text"
  }
export const useLoginFields = () => {

    return [

        {
            id: TextFieldTypes.email,
            label: "Email",
            required: true,
            input: {
                props: {
                    type: TextFieldTypes.email,
                    placeholder: "joe@bloggs.com"
                },
                state: useFormInput("")
            }
        },
        {
            id: TextFieldTypes.password,
            label: "Password",
            required: true,
            input: {
                
                props: {
                    type: TextFieldTypes.password,
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        }
    ];
}