import './ExploreContainer.css';
import '../../node_modules/leaflet/dist/leaflet.css';
import '../../node_modules/leaflet/dist/leaflet-src.js';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Compon</a></p>
      
    </div>
  );
};

export default ExploreContainer;