import React, { useState } from 'react';
import {IonImg, IonPage, IonButtons, IonMenuButton, IonToolbar, IonTitle, IonLabel, IonButton, IonContent, IonItem, IonInput, IonHeader} from '@ionic/react';
import axios from 'axios';
import '../pages/Login.css';
import {TextFieldTypes, useLoginFields} from '../data/fields';
import { Storage } from '@ionic/storage';



import { Device, DeviceId } from '@capacitor/device';
import { Redirect, useParams } from 'react-router';
import { validateForm } from '../data/utils';
import { Preferences } from '@capacitor/preferences';


const logDeviceInfo = async () => {
    //const info = await Device.getBatteryInfo();
    const info = await Device.getInfo();
    console.log(info);
    alert('Platform: '+info.platform);
    alert('OS: '+info.operatingSystem);

    // https://capacitorjs.com/docs/apis/device#getid
    const uuid_obj = await Device.getId();
    alert('Ionic Device UUID is: '+uuid_obj.uuid);

    // TODO: find capacitor version of this plugin: cordova-plugin-device-name
    //const fooname = (window as any).device.name;
    //console.log('window name: '+fooname);
    return uuid_obj;
};

export const LoginContainer: React.FC = () => {
    const params = useParams();
    const fields = useLoginFields();
    const [errors, setErrors] = useState<any>([]);
    const doLoginActions = () => {
        alert('Login button pressed');
         //verify username and password exist and are not empty, not blank, etc.
        const errors = validateForm(fields);
        setErrors(errors);
        console.log(`errors: ${errors}`);
        if(!errors.length){
            
            // TODO: Call axios api.purephone.org/v1/login, also sending them our device info, and get api key back from purephone.org
            const uuid = logDeviceInfo();
            let api_key: any;
            const setApiKey = async () =>{
                await Preferences.set({
                    key: 'api_key',
                    value: `${api_key.apikey}`,
                });
                console.log(`api_key: ${api_key.apikey}`);
            };
            login(fields,uuid).then((val)=>{
                api_key= val;
                setApiKey();
            });
            
            
            // // TODO: Assume login accepted, redirect to main screen whatever that is
            const router = document.querySelector('ion-react-router');
            const routeRedirect = document.createElement('ion-route-redirect');
            routeRedirect.setAttribute('from', './page/Login');
            routeRedirect.setAttribute('to', './page/AppConfiguration');
            <Redirect to={'/page/AppConfiguration'}></Redirect>
            // if (!isLoggedIn) {
            //   router?.appendChild(routeRedirect);
            // }
             // TODO: Else, tell user their user or pass is incorrect
    
        }
    }
    function login(fields: { id: TextFieldTypes; 
                             label: string; 
                             required: boolean; 
                             input: { props: { type: TextFieldTypes; 
                                placeholder: string; }; state: { value: string; 
                                    reset: (newValue: React.SetStateAction<string>) => void; 
                                    onIonChange: (e: any) => Promise<void>; 
                                    onKeyUp: (e: any) => Promise<void>; }; }; }[], deviceInfo: Promise<DeviceId>){
        const data = `password=${fields[0].input.state.value}&username=${fields[1].input.state.value}`;
        return axios.post(
            'https://api.purephone.org/v1/login',
            data,       
                    // uuid: deviceInfo,
                )
                .then((response) => {
                    // console.log(response);
                    return response.data;
                })
                .catch(function (error){
                    alert('error response');
                })
    }

    // const sendRequest = () => {
    //     return axios
    //         .get('https://api.purephone.org/v1/settings/8B1X11VKP',{
    //             headers:{
    //                 'app-id':'pca-app',
    //                 'Content-Type' : 'application/json'
    //             },
    //         })
    //         .then((response) => {
    //             console.log(response);
    //             return response;
    //         })
    //         .catch(function (error) {
    //             alert('error response');
    //         })
    // };

    // React.useEffect(() => {
    //     sendRequest().then(data =>{
    //         if (data && typeof data === 'object' && !Array.isArray(data)) {
    //             if ('data' in data) {
    //                 console.log(data.data);
    //             } else {
    //                 console.log('No response data from Line 34 of LoginContainer.tsx');
    //             }
    //         }
    //     });
    // }, []);

    //const [checked, setChecked] = useState(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>
                        Purephone Login
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonImg src="assets/new_pure_logo_2.png" alt="purephone logo"></IonImg>
                {fields.map(field =>{
                    return <>
                    <IonItem>
                        <IonLabel>{field.label}</IonLabel>
                        <IonInput {...field.input.props} {...field.input.state}/>;
                    </IonItem>
                    </>
                })}
                <IonButton shape="round" color="success" size="large" onClick={doLoginActions}>Login</IonButton>
            </IonContent>
        </IonPage>
    );
};
export default LoginContainer;
