import './MapContainerComp.css';
import '../../node_modules/leaflet/dist/leaflet.css';
import '../../node_modules/leaflet/dist/leaflet-src.js';

import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import {
  IonAvatar, IonButton, IonCol, IonContent, IonGrid, IonImg, IonItem, IonItemSliding, IonLabel, IonList, IonModal, IonPage, IonRow, IonSearchbar, IonText
  , useIonModal
  , useIonViewDidEnter
  , useIonViewDidLeave
  , useIonViewWillEnter
  , useIonViewWillLeave
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import React from 'react';
import L, { Icon, LatLng } from 'leaflet';
import marker from '../marker.svg';
import { list } from 'ionicons/icons';

let map: L.Map|null = null;

const myIcon = new Icon({
  iconUrl: marker,
  iconSize: [32, 32]
});
interface ContainerProps {
  name: string;
}
const api_key = "FAKE-PUREPHONE-3J92482XH";

const ModalBody = (
  <IonContent className="ion-padding">
    <IonList>
      <IonButton>
        <IonItem>
          <IonAvatar slot="start">
            <IonImg src="https://i.pravatar.cc/300?u=b" />
          </IonAvatar>
          <IonLabel>
            <h2>Phone1</h2>
            <p>nickname</p>
          </IonLabel>
        </IonItem>
      </IonButton>
    </IonList>
  </IonContent>
);
function LocatePhone(loc: LatLng){
  if(map != null){
    map.flyTo(loc);
  }
}
function PhoneModal(){
  const [phones, setPhones] = useState<any>([]);
  let ModalBody_dyn = ModalBody;
  const sendRequest = () => {
    const data = `api_key=${api_key}`;
    return axios.post('https://api.purephone.org/v1/phones',
    data)
      .then((response) => {
        console.log(response);
        return response;
      })
  };
  React.useEffect(() => {
    sendRequest().then(data => {
      setPhones(data.data)
    });
  }, []);
      //add elements to the modal body
    ModalBody_dyn = (<IonContent className="ion-padding">
    <IonList>
      {phones.map((item: { [x: string]: any},index: any) => {
        const loc = new LatLng(item["last_seen_lat"], item["last_seen_long"]);
        return (
          <IonButton key={index} onClick={() => LocatePhone(loc)} size="large">
          <IonItem>
            <IonAvatar slot="start">
              <IonImg src="https://i.pravatar.cc/300?u=b" />
            </IonAvatar>
            <IonLabel>
              <h2>Phone {item["id"]}</h2>
              <p>{item["owner_nickname"]}</p>
              <p>{item["last_seen_lat"]} {item["last_seen_long"]}</p> //change to nearest street 
            </IonLabel>
          </IonItem>
        </IonButton>
        )
      })}
    </IonList>
  </IonContent>)
  
  return ModalBody_dyn;

}

function LocationMarker() {
  const [position, setPosition] = useState<LatLng | null>(null)
  const [listItems, setListItems] = useState<any>([]);
  let markers = [];
  const sendRequest = () => {
    const data = `api_key=${api_key}`;
    return axios.post('https://api.purephone.org/v1/phones', data)
      .then((response) => {
        console.log(response);
        return response;
      })
  };
  React.useEffect(() => {
    sendRequest().then(data => {
      setListItems(data.data)
    });
  }, []);

  markers = listItems.map((item: { [x: string]: any; },index: React.Key | null | undefined) => {
    const loc = new LatLng(item["last_seen_lat"], item["last_seen_long"]);
    const nickname = item["owner_nickname"];
    return(
    <Marker position={loc} key={index} icon={myIcon}>
      <Popup>{nickname}</Popup>
    </Marker>)
  })
  return markers
}

function SetMap(){
  map = useMap();
  return null;  
}

export const MapContainerComp: React.FC<ContainerProps> = ({ name }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    console.log(LocationMarker);
    setIsVisible(true);
  }, [LocationMarker]);

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
    map?.locate({setView: true, maxZoom: 16});
  });
  useIonViewWillLeave(() => {
    console.log('ionViewWillLeave event fired');
  });
  useIonViewDidEnter(() => {
    map?.invalidateSize();
    console.log('ionViewDidEnter event fired');
  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
    setIsVisible(true);
  });
 
  const modal = useRef<HTMLIonModalElement>(null);

  const [open, setOpen] = useState(false);

  const [present, dismiss] = useIonModal(PhoneModal, {
    dismiss: () => dismiss()

  });

  const closeModal = () => {
    setOpen(false);
  }
  const modalOptions = {
    onDidDismiss: () => dismiss(),

    breakpoints: [0, 0.1, 0.5, 0.7],
    initialBreakpoint: 0.5,
    backdropBreakpoint: 0.7
  };  

  return (

    <div className="flex-container" >
     <IonGrid>
      <IonRow>
        <IonCol>
          <IonText color='primary'>
            {/* <h1>Map</h1> */}
          </IonText>
        </IonCol>
      </IonRow>
        <IonRow>
          <IonCol size="12" size-sm="3"> 
             <MapContainer center={[50, 50]} zoom={13} scrollWheelZoom={true} touchZoom={true}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              
              <LocationMarker />
              <SetMap/>
            </MapContainer>
          </IonCol>
        </IonRow>
      <IonRow>
        <IonButton onClick={() => present(modalOptions)}>My list of Phones</IonButton>
      </IonRow>
    </IonGrid>

    </div>

  );
};

export default MapContainerComp;
