import './ExploreContainer.css';
import '../../node_modules/leaflet/dist/leaflet.css';
import 'leaflet';
import { Virtuoso } from 'react-virtuoso';
import "./ConfigContainer.css";
import React, {useState} from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonText,
  IonIcon,
  IonSearchbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRadioGroup,
  IonRadio,
  IonToast,
} from '@ionic/react';
import {moonOutline} from 'ionicons/icons';
import {logoYoutube} from 'ionicons/icons';

import axios from 'axios';
import { Preferences } from '@capacitor/preferences';

interface test{
  listType: any
  setListType: any
  refreshUsers: any
}

let api_key = "";
let textType = "Blocked Apps"
const checkName = () => {
  Preferences.get({ key: 'api_key' }).then(val =>{
    if(val.value){
      api_key= val.value;
    }
  });
};
checkName();

function api_post(url: string, data: any){
  return axios.post(
    url,
    data,
).then((response)=>{
  console.log(response);
  return response;
})
}

const InfiniteScrollExample: React.FC<test> = ({listType, setListType, refreshUsers}) => {
  
  const [showToast, setShowToast] = useState(false);
  const [appList, setAppList] = useState<any>([]);
  const [viewApps, setViewApps] = useState<any>([]);
  const searchbar = document.querySelector('ion-searchbar');

  if(searchbar != null){
    searchbar.addEventListener('ionInput', handleInput);  
  }
  function handleInput(event: any){
    const query = event.target.value.toLowerCase();
      requestAnimationFrame(() => {
        setViewApps(appList.filter((item: { [x: string]: string; }) => 
          item["package"].toLowerCase().indexOf(query) > -1
        ))
      });
  }
  function sendRequest(app_name:string) {
    const data = `api_key=${api_key}&app_name=${app_name}`;
    return api_post('https://api.purephone.org/v1/app/find',data);
  }
  function addApp(app_name: string){
    const data = `api_key=${api_key}&package_name=${app_name}&list_type=${listType}`;
    return api_post('https://api.purephone.org/v1/userapp/add',data);
  }
  React.useEffect(() => {
    sendRequest('').then(data => {
      setAppList(data.data);
      console.log("test");
    });
  }, [])
  React.useEffect(() => {
    console.log(appList);
    setViewApps(appList);
  }, [appList])

  return (
    <>
      <IonSearchbar />
      <Virtuoso
        style={{ height: "200px", }}
        data={viewApps}
        itemContent={(index, app) => {

          return (
            <>
            <IonItemSliding>
            <IonItem key={index}>
              <IonLabel><div>{app["name"]}</div></IonLabel>
            </IonItem>
            <IonItemOptions>
              <IonItemOption onClick={()=>{
                addApp(app["package"]).then(()=>{refreshUsers()});
                setShowToast(true);
              }}>Add</IonItemOption>
            </IonItemOptions>
            </IonItemSliding>
            <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="App Successfully Added!"
        duration={1500}
        position= "top"
      />
            </>
          );
        } 
        } 
        />
        </>
  );
};

export const ConfigContainer: React.FC = () => {
  const [blocked, setBlocked] = useState<any>([]);
  const [listType, setListType] = useState("blocked");
  const [yttn, setyttn] = useState(false);
  const [track,setTrack] = useState(false);

  function youtubeThumbsStatus(){
    const data = `api_key=${api_key}&new_value=${yttn}&short_code=YTTN`;
    return api_post('https://api.purephone.org/v1/setting/update', data);
  }

  React.useEffect(()=> {
    youtubeThumbsStatus()
  },[yttn])

  /**
   * Updates setting for list type
   */
  React.useEffect(()=>{
    let val;
    if (listType == "blocked"){
      val = true;
    }else if (listType == "allowed"){
      val = false;
    }
    const data = `api_key=${api_key}&new_value=${val}&short_code=AAAE`;
    api_post('https://api.purephone.org/v1/setting/update', data);
  },[listType])

  React.useEffect(()=>{
    const data = `api_key=${api_key}&new_value=${track}&short_code=TMCM`;
    api_post('https://api.purephone.org/v1/setting/update', data);
  },[track])

  const requestUserApps = () => {
    const data = `list_type=${listType}&api_key=${api_key}`;
    return api_post('https://api.purephone.org/v1/userapps',data);
  };
  function deleteUserApp(userApp:string){
    const data = `list_type=${listType}&package_name=${userApp}&api_key=${api_key}`;
    return api_post('https://api.purephone.org/v1/userapp/remove',data);
  }

  React.useEffect(() => {
    requestUserApps().then(data =>{
      setBlocked(data.data);
    });
  }, []);
  React.useEffect(()=>{
    requestUserApps().then(data=>{
      setBlocked(data.data);
    })
  },[listType]);
  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ToggleExamples</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonText>Track Child location</IonText>
            <IonToggle slot="end" color="primary" onIonChange={(checked)=>{
              setTrack(checked.detail.checked);
            }}></IonToggle>
          </IonItem>

          <IonItem lines= "full">
          <IonIcon icon={logoYoutube}></IonIcon>
          &nbsp;
            <IonText>Youtube Thumbnails</IonText>
          <IonToggle slot="end" color="primary" onIonChange={(checked) =>{
                setyttn(checked.detail.checked);
            }
          }
            >
            </IonToggle></IonItem>
          <IonItem lines="full">

            <IonIcon icon={moonOutline}> </IonIcon>
            &nbsp;

            <IonLabel>
              Toggle Dark Theme
            </IonLabel>

            <IonToggle slot="end" name="themeToggle" onClick={()=> toggleDarkModeHandler()} />
          </IonItem>
          <IonGrid>

            <IonRadioGroup value={listType} >
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Allow all apps except</IonLabel>
                    <IonRadio slot="end" value="blocked" onClick={()=>{
                      setListType("blocked");
                      console.log("allowed")
                      textType = "Blocked Apps"
                    }}></IonRadio>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel>Block all apps except</IonLabel>
                    <IonRadio slot="end" value="allowed" onClick={()=>{
                      setListType("allowed");
                      console.log('blocked');
                      textType = "Allowed Apps"
                    }} ></IonRadio>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonRadioGroup>


            <IonRow>
              <IonCol>
                <h2>Apps</h2>
                <InfiniteScrollExample listType={listType} setListType={setListType} refreshUsers={()=>{setBlocked(requestUserApps().then(data =>{
      setBlocked(data.data);
    }));}}></InfiniteScrollExample>
                <IonRow></IonRow>
                <IonCol></IonCol>
                  <h2>{textType}</h2>
                <>
                  <Virtuoso
                      style={{ height: "200px", }}
                      data={blocked}
                      itemContent={(index,app) => {
                        return (
                            <>
                              <IonItemSliding>
                                <IonItem key={index}>
                                  <IonLabel><div>{app["name"]}</div></IonLabel>
                                </IonItem>
                                <IonItemOptions>
                                  <IonItemOption onClick={()=>{
                                    deleteUserApp(app["package_name"]).then(()=>{
                                      setBlocked(requestUserApps().then(data =>{
                                        setBlocked(data.data);
                                      }));
                                    });
                                  }}>Remove</IonItemOption>
                                </IonItemOptions>
                              </IonItemSliding>
                            </>
                        );
                      }
                      }
                  />
                </>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default ConfigContainer;